<template>
  <div>
    <v-expansion-panels v-model="defaultOpenPanelIndex" class="content-add-edit-expansion-panels" flat>
      <v-expansion-panel active-class="expanded" class="mt-0" :disabled="isMapBorderEnabled">
        <v-expansion-panel-header>
          <PtrIcon class="expansion-panel-header-icon" icon="information" />
          <div class="expansion-panel-header-text">{{ $t("contents.mapDesigner.basic-information") }}</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <LevelTypeSelectionComponent
            :level-type="selectedLevelType"
            @typeSelected="(type) => onLevelTypeSelected(type)"
          />
          <v-form ref="levelForm" v-model="isFormValid" class="mt-2">
            <v-row>
              <v-col class="py-1">
                <v-text-field
                  id="level-index"
                  v-model="levelData.levelIndex"
                  :rules="[rules.required, isLevelIndexValid]"
                  :label="$t(`${translationPath}level-index`)"
                  outlined
                  hide-details="auto"
                  dense
                  :disabled="isEdit"
                  @keydown="setFormDirty"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-1">
                <v-text-field
                  id="level-long-title"
                  v-model="levelData.levelLongTitle"
                  :rules="[rules.required, isLongTitleValid]"
                  :label="$t(`${translationPath}long-name-required`)"
                  outlined
                  hide-details="auto"
                  dense
                  @keydown="setFormDirty"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-1">
                <v-text-field
                  id="level-short-title"
                  v-model="levelData.levelShortTitle"
                  :rules="[rules.required, isShortTitleValid]"
                  :label="$t(`${translationPath}short-name-required`)"
                  outlined
                  hide-details="auto"
                  dense
                  @keydown="setFormDirty"
                />
              </v-col>
            </v-row>
            <v-row v-if="!isEdit || floorPlanUrl">
              <v-col class="py-1">
                <div class="my-2 floor-plan-title">
                  {{ $t(`${translationPath}floor-plan`) }}
                </div>
                <div v-if="!isEdit">
                  <div v-if="!file" class="pb-2 floor-plan-description">
                    {{ $t(`${translationPath}floor-plan-description`) }}
                  </div>
                  <DropFile
                    id="upload-map-file"
                    ref="dropFileComponent"
                    v-model="file"
                    :title="$t(`${translationPath}browse-or-upload`)"
                    :allowed-extensions="allowedExtensions"
                    :file-size-with-extension="{ dxf: 100, dwg: 50, pdf: 50 }"
                    :max-file-size-m-b="100"
                    drop-anywhere
                    @clear="clearFile"
                  >
                    <template #description>
                      <div>
                        <div class="drop-file-subtitle property-header">
                          {{ $t(`${translationPath}allowed-extensions`) }}
                        </div>
                        <div class="drop-file-subtitle property-header mb-2">
                          {{ $t(`${translationPath}allowed-extensions-experimental`) }}
                        </div>
                      </div>
                    </template>
                  </DropFile>
                </div>
                <div v-else-if="floorPlanUrl">
                  <v-btn color="primary" class="download-button px-2 mb-2" block @click="downloadOriginalFloorPlan">
                    <v-col>{{ $t(`${translationPath}download-floor-plan`) }}</v-col>
                    <PtrIcon icon="download" class="button-icon" />
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <v-row class="form-footer mb-1">
              {{ $t("contents.mapDesigner.required") }}
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel
        :disabled="!anchorFeatures.length || isAnchorEditModeActive"
        :class="{ hidden: anchorFeatures.length === 0 }"
        active-class="expanded"
        class="mt-0"
        @click="onPositionOnEarthClicked"
      >
        <v-expansion-panel-header>
          <PtrIcon class="expansion-panel-header-icon" icon="position" />
          <div class="expansion-panel-header-text">{{ $t(`${translationPath}position`) }}</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <AnchorPointInputs
            v-if="shouldRenderAnchorInputComponent"
            ref="anchorPointsComponent"
            :anchor-features="anchorFeatures"
            :all-features="features"
            @valid="(isValid) => (isAnchorPointCoordsValid = isValid)"
            @isAnchorEditModeActive="(value) => (isAnchorEditModeActive = value)"
            @geotransform="(value) => geotransform(value)"
            @changeAnchors="(value) => changeAnchors(value)"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <slot name="danger-zone"></slot>
    </v-expansion-panels>
    <MarkCornersPopup v-if="shouldDisplayMarkCornersPopup" @close="closeMarkCornersPopup"></MarkCornersPopup>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import geojsonValidator from "geojson-validation";
import DropFile from "@/components/shared/DropFile.vue";
import PtrIcon from "@/components/shared/PtrIcon.vue";
import MarkCornersPopup from "@/components/shared/MarkCornersPopup.vue";
import LevelTypeSelectionComponent from "@/components/LevelTypeSelectionComponent.vue";
import AnchorPointInputs from "@/components/AnchorPointInputs.vue";
import LevelService from "@/services/LevelService";
import ValidationHelpers from "@/helpers/ValidationHelpers";
import Helpers from "@/helpers/Helpers";
import MapHelpers from "@/helpers/MapHelpers";
import ToastHelpers from "@/helpers/ToastHelpers";

const INDEX_OF_POSITION_ON_EARTH_PANEL = 1;

export default {
  name: "LevelAddEditView",
  components: { PtrIcon, DropFile, LevelTypeSelectionComponent, AnchorPointInputs, MarkCornersPopup },
  beforeRouteLeave(to, from, next) {
    if (!to?.path?.endsWith("/advanced-configurations") && !from?.path?.endsWith("/advanced-configurations")) {
      this.dialogHandler(next);
    } else {
      next();
    }
  },
  props: {
    featureId: [String, Number]
  },
  data: () => ({
    translationPath: "contents.levels.",
    isEdit: false,
    isFormValid: false,
    rules: {
      required: (value) => ValidationHelpers.isRequired(value)
    },
    levelData: {
      levelShortTitle: "",
      levelLongTitle: "",
      levelIndex: undefined
    },
    file: undefined,
    isFileUploaded: false,
    isBulkUploadSuccessful: false,
    allowedExtensions: ["geojson", "json", "pdf", "dwg", "dxf"],
    confirmDialog: undefined,
    showDiscardChangesModal: false,
    isInitialDataLoaded: true,
    defaultOpenPanelIndex: 0,
    fileExtension: "",
    fileContents: undefined,
    selectedLevelType: "",
    features: [],
    anchorFeatures: [],
    isAnchorEditModeActive: false,
    isAnchorPointCoordsValid: true,
    isMarkCornersPopupShown: false,
    shouldDisplayMarkCornersPopup: false
  }),

  computed: {
    ...mapState("MAP", [
      "isMapReady",
      "currentClient",
      "currentSite",
      "currentBuilding",
      "currentLevel",
      "isMapBorderEnabled"
    ]),
    ...mapState("CONTENT", ["mapObjects", "isFormDirty", "levelStatuses"]),
    ...mapState("ADVANCED_CONFIGS", ["cachedExtraData"]),
    taxonomy() {
      return this.$store.state.taxonomy;
    },
    isLevelConfigValid() {
      if (!this.isEdit) {
        return (
          this.isFormValid &&
          this.isTypeValid &&
          this.isAnchorPointCoordsValid &&
          !this.isAnchorEditModeActive &&
          this.file !== undefined
        );
      } else {
        return this.isFormValid && this.isTypeValid;
      }
    },
    isTypeValid() {
      return this.selectedLevelType !== "" && this.selectedLevelType !== undefined;
    },
    shouldRenderAnchorInputComponent() {
      return this.defaultOpenPanelIndex === INDEX_OF_POSITION_ON_EARTH_PANEL;
    },
    floorPlanUrl() {
      return this.levelStatuses[this.levelData?.levelIndex]?.floorPlanUrl;
    },
    ...mapGetters("MAP", ["map", "currentClientObject"])
  },

  watch: {
    $route() {
      this.setMapUI();
    },
    featureId() {
      this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
      if (this.featureId === "add" || this.featureId === undefined) {
        this.selectedlevelId = undefined;
        return;
      }
      if (this.$route.name !== "Levels") {
        this.initialize();
      }
    },
    file() {
      this.fileChanged(this.file);
      this.$store.commit("CONTENT/IS_FORM_DIRTY", true);
    },
    levelData: {
      deep: true,
      handler() {
        this.isInitialDataLoaded = true;
      }
    },
    isLevelConfigValid() {
      if (this.isFormDirty) {
        this.$emit("valid", this.isLevelConfigValid);
      }
    },
    isFormDirty() {
      if (this.isLevelConfigValid) {
        this.$emit("valid", this.isLevelConfigValid);
      }
    },
    features() {
      if (this.features.length) {
        this.$store.dispatch("MAP/ADD_MAP_OBJECTS_TO_MAP", this.features);
        this.anchorFeatures = this.features.filter(
          (feature) => feature.properties.typeCode === "georeferencing-anchor"
        );
        if (!this.shouldRenderAnchorInputComponent) {
          this.calculateBorders(this.features);
        }
      } else {
        this.anchorFeatures = [];
      }
    },
    shouldRenderAnchorInputComponent() {
      if (!this.shouldRenderAnchorInputComponent) {
        this.calculateBorders(this.features);
      }
    },
    mapObjects() {
      this.anchorFeatures = this.mapObjects.filter(
        (feature) => feature.properties.typeCode === "georeferencing-anchor"
      );
      this.features = this.mapObjects;
    }
  },

  async created() {
    MapHelpers.enableMapInteractions();
    this.setMapUI();
    await this.$store.dispatch("SET_TAXONOMY");
    this.initialize();
    this.isMarkCornersPopupShown = localStorage.getItem("isMarkCornersPopupShown");
  },

  async beforeDestroy() {
    MapHelpers.disableMapInteractions();
    this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
    this.$store.commit("MAP/IS_MAP_BORDER_ENABLED", false);
    this.$store.commit("MAP/GUIDANCE_MESSAGE", undefined);
    MapHelpers.removeFloorOutlineBorders();
  },

  methods: {
    async initialize() {
      this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
      let { clientId, siteId, buildingId, levelId } = this.$route.params;
      await Promise.all([
        this.$store.dispatch("CONTENT/SET_CLIENTS"),
        this.$store.dispatch("CONTENT/SET_SITES", { clientId }),
        this.$store.dispatch("CONTENT/SET_BUILDINGS", { siteId }),
        this.$store.dispatch("CONTENT/SET_LEVELS", { buildingId })
      ]);

      this.$store.dispatch("MAP/SET_CURRENT_CLIENT", { client: clientId });
      this.$store.dispatch("MAP/SET_CURRENT_SITE", { site: siteId });
      this.$store.dispatch("MAP/SET_CURRENT_BUILDING", { building: buildingId });
      this.selectedlevelId = levelId;
      this.isEdit = this.$route.name === "LevelEdit" && levelId !== "add";
      if (this.isEdit) {
        this.isInitialDataLoaded = false;
        await this.$store.dispatch("MAP/SET_CURRENT_LEVEL", { level: this.selectedlevelId });
        this.levelData = await LevelService.getLevel(buildingId, this.selectedlevelId);
        this.$store.commit(
          "ADVANCED_CONFIGS/INITIAL_SDK_CONFIGURATIONS",
          JSON.stringify(this.levelData?.levelExtraData)
        );
        this.selectedLevelType =
          this.levelData?.levelExtraData?.levelType?.toLowerCase() ||
          this.currentClientObject?.clientExtraData?.defaultLevelType?.toLowerCase() ||
          "";
        if (this.levelData?.levelExtraData) {
          this.$store.dispatch("ADVANCED_CONFIGS/SET_CACHED_EXTRA_DATA", {
            configs: Object.entries(this.levelData.levelExtraData)
          });
          this.$store.commit(
            "ADVANCED_CONFIGS/INITIAL_EXTRA_DATA",
            JSON.stringify(Object.entries(this.levelData.levelExtraData))
          );
        } else {
          console.debug("Failed to initialize extraData - level doesn't have levelExtraData");
        }
      } else {
        this.$store.dispatch("ADVANCED_CONFIGS/CLEAR_ALL_CONFIGURATIONS");
        await this.$store.dispatch("MAP/SET_CURRENT_LEVEL", { level: undefined });
        this.selectedLevelType = this.currentClientObject?.clientExtraData?.defaultLevelType?.toLowerCase() || "";
      }
      this.$store.dispatch("MAP/DISPLAY_BUILDING_BORDERS", { enableCurrentBuildingFilter: true });
    },
    setMapUI() {
      this.$store.commit("MAP/SHOW_UI_ICON", { iconName: "target" });
      this.$store.commit("MAP/SHOW_UI_ICON", { iconName: "zoom" });
    },
    goBack(goBackWithLevelData = false) {
      if (this.isEdit && goBackWithLevelData) {
        this.$router
          .push({ name: "Levels", params: { levelId: this.currentLevel, contentType: "levels" } })
          .catch((e) => console.log(e.message));
      } else {
        this.$router
          .push({
            name: "Levels",
            params: { contentType: "levels", levelId: goBackWithLevelData ? this.levelData.levelIndex : undefined }
          })
          .catch((e) => console.log(e.message));
      }
    },
    async save() {
      let request = {
        levelIndex: this.levelData.levelIndex,
        levelShortTitle: this.levelData.levelShortTitle?.trim(),
        levelLongTitle: this.levelData.levelLongTitle?.trim(),
        levelExtraData: { ...Helpers.arrayToObject(this.cachedExtraData), levelType: this.selectedLevelType }
      };
      let response;
      if (this.isEdit) {
        response = await LevelService.updateLevel(this.currentBuilding, request.levelIndex, request);
        ToastHelpers.createSuccessToast(`${this.levelData.levelLongTitle.trim()} ${this.$t("contents.updated")}`);
      } else {
        response = await LevelService.createLevel(this.currentBuilding, request.levelIndex, request);
      }

      if (response?.createdTimestampUtcEpochSeconds) {
        if (!this.isEdit) {
          let base64Data;
          if (this.fileExtension === "json" || this.fileExtension === "geojson") {
            base64Data = this.b64EncodeUnicode(this.fileContents);
          } else {
            base64Data = this.fileContents.substring(this.fileContents.indexOf("base64,") + 7);
          }

          request = {
            fileType: this.fileExtension,
            floorPlanFileBase64: base64Data
          };

          response = await LevelService.uploadOriginalFloorPlan(
            this.currentClient,
            this.currentSite,
            this.currentBuilding,
            this.levelData.levelIndex,
            request
          );
        }

        if (response?.statusCode === 200 || response?.createdTimestampUtcEpochSeconds) {
          await this.uploadMapFile();
          if (this.isBulkUploadSuccessful) {
            this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
            await this.$store.dispatch("CONTENT/SET_LEVELS", { forceUpdate: true, buildingId: this.currentBuilding });
            await this.$store.dispatch("CONTENT/SET_BUILDINGS", { forceUpdate: true, siteId: this.currentSite });
            await this.$store.dispatch("CONTENT/SET_SITES", { forceUpdate: true, clientId: this.currentClient });
            return true;
          } else {
            return false;
          }
        } else {
          await LevelService.deleteLevel(this.currentBuilding, this.levelData.levelIndex);
          ToastHelpers.createErrorToast(response?.message || this.$t(`${this.translationPath}upload-failed`));
          return false;
        }
      } else {
        ToastHelpers.createErrorToast(response?.message || `${request.levelLongTitle} ${this.$t("contents.failed")}`);
        return false;
      }
    },

    b64EncodeUnicode(str) {
      // first we use encodeURIComponent to get percent-encoded UTF-8,
      // then we convert the percent encodings into raw bytes which
      // can be fed into btoa.
      return window.btoa(
        encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
          return String.fromCharCode("0x" + p1);
        })
      );
    },
    async uploadMapFile() {
      if (!this.features.length || !this.isFileUploaded) {
        this.isBulkUploadSuccessful = true;
        return;
      }
      let response = await LevelService.bulkUpload(this.currentSite, this.currentBuilding, this.levelData.levelIndex, {
        type: "FeatureCollection",
        features: this.features
      });
      if (response?.createdTimestampUtcEpochSeconds) {
        this.isBulkUploadSuccessful = true;
        let message = this.isEdit ? "updated" : "created";
        ToastHelpers.createSuccessToast(`${this.levelData.levelLongTitle.trim()} ${this.$t("contents." + message)}`);
      } else {
        this.isBulkUploadSuccessful = false;
        if (!this.isEdit) {
          await LevelService.deleteLevel(this.currentBuilding, this.levelData.levelIndex);
        }
        ToastHelpers.createErrorToast(response?.error?.message || this.$t(`${this.translationPath}upload-failed`));
      }
    },
    validateFile(content) {
      return !content.features?.some((feature) => {
        let isInvalid = false;
        if (!this.taxonomy[feature?.properties?.typeCode]) {
          isInvalid = true;
          ToastHelpers.createErrorToast(this.$t(`${this.translationPath}file-validation-type-code`));
        } else if (
          this.taxonomy[feature?.properties?.typeCode].properties.class === "poi" &&
          !feature?.properties?.name
        ) {
          isInvalid = true;
          ToastHelpers.createErrorToast(this.$t(`${this.translationPath}file-validation-name`));
        }
        return isInvalid;
      });
    },
    showAdvancedConfig() {
      this.$router
        .push({
          name: this.isEdit ? "EditLevelAdvancedConfigurations" : "AddLevelAdvancedConfigurations"
        })
        .catch((e) => console.log(e.message));
    },
    fileChanged(files) {
      if (!files) {
        return;
      }
      const file = files[files.length - 1];
      const fileNameArr = file.name.split(".");
      this.fileExtension = fileNameArr[fileNameArr.length - 1].toLowerCase();

      if (!this.allowedExtensions.some((ext) => ext === this.fileExtension)) {
        let msg = this.allowedExtensions.reduce((exts, ext) => {
          exts += exts.length == 0 ? ext : `, ${ext}`;
          return exts;
        }, "");
        ToastHelpers.createErrorToast(
          `"${this.fileExtension}" ${this.$t(this.translationPath + "invalid-file-extension")} ` + msg
        );
        return;
      }
      const reader = new FileReader();

      if (this.fileExtension === "json" || this.fileExtension === "geojson") {
        try {
          reader.readAsText(file);
        } catch (e) {
          ToastHelpers.createErrorToast(this.$t(`${this.translationPath}file-read-failed`));
        }
      } else {
        try {
          reader.readAsDataURL(file);
        } catch (e) {
          ToastHelpers.createErrorToast(this.$t(`${this.translationPath}file-read-failed`));
        }
      }

      reader.onload = async (e) => {
        this.fileContents = e.target.result;
        if (this.allowedExtensions.includes(this.fileExtension)) {
          this.isFileUploaded = true;

          if (this.fileExtension === "json" || this.fileExtension === "geojson") {
            await this.handleJSONFiles();
          } else {
            await this.handleBinaryFile();
          }
        }
      };
    },
    async clearFile() {
      this.isFileUploaded = false;
      this.file = undefined;
      this.features = [];
      if (this.isEdit) {
        await this.$store.dispatch("CONTENT/SET_MAP_OBJECTS", {
          levelIndex: this.levelData.levelIndex,
          buildingInternalIdentifier: this.currentBuilding,
          forceUpdate: true
        });
        this.$store.dispatch("MAP/ADD_MAP_OBJECTS_TO_MAP");
      } else {
        this.$store.dispatch("MAP/REMOVE_MAP_OBJECTS_FROM_MAP");
        MapHelpers.removeFloorOutlineBorders();
      }
    },
    isLongTitleValid(value) {
      return ValidationHelpers.isLongTitleValid(value) || this.$t(`${this.translationPath}invalid-long-title`);
    },
    isShortTitleValid(value) {
      return ValidationHelpers.isShortTitleValid(value) || this.$t(`${this.translationPath}invalid-short-title`);
    },
    isLevelIndexValid(value) {
      return (
        value !== undefined &&
        (ValidationHelpers.isLevelIndexValid(value) || this.$t(`${this.translationPath}invalid-index`))
      );
    },
    showDialog() {
      this.showDiscardChangesModal = true;
      return new Promise((resolve, reject) => {
        this.confirmDialog = { resolve, reject };
      });
    },
    async dialogHandler(next) {
      if (this.isFormDirty) {
        try {
          await this.showDialog();
          next();
        } catch (err) {
          next(false);
        }
      } else if (next) {
        next();
      }
    },
    setFormStatusClean() {
      this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
    },
    setFormDirty() {
      this.$store.commit("CONTENT/IS_FORM_DIRTY", true);
    },
    async deleteContent() {
      const response = await LevelService.deleteLevel(this.$route.params.buildingId, this.$route.params.levelId);
      if (response?.createdTimestampUtcEpochSeconds) {
        ToastHelpers.createSuccessToast(`Level ${this.$t("delete-success")}`);
        await this.$store.dispatch("MAP/SET_CURRENT_LEVEL", { level: undefined });
        await this.$store.dispatch("CONTENT/SET_LEVELS", { forceUpdate: true, buildingId: this.currentBuilding });
        await this.$store.dispatch("CONTENT/SET_BUILDINGS", { forceUpdate: true, siteId: this.currentSite });
        return true;
      } else {
        ToastHelpers.createErrorToast(response?.message || this.$t(`delete-failed`));
        return false;
      }
    },
    async handleJSONFiles() {
      let content;
      try {
        content = JSON.parse(this.fileContents);
      } catch (e) {
        this.isFileUploaded = false;
        this.file = undefined;
        ToastHelpers.createErrorToast(this.$t(`${this.translationPath}file-invalid`, { type: this.fileExtension }));
        return;
      }

      if (!geojsonValidator.valid(content)) {
        ToastHelpers.createErrorToast(this.$t(`${this.translationPath}invalid-geojson-file`));
        this.clearFile();
        return;
      }

      if (!this.validateFile(content)) {
        this.clearFile();
        return;
      }
      const response = await LevelService.calculateAnchors({ geojson: content });
      this.features = content?.features.filter((feature) => feature.properties.typeCode !== "georeferencing-anchor");
      if (response?.result?.features) {
        response.result.features.forEach((feature) => {
          feature.properties.fid = Helpers.generateUuid();
          this.features.push(feature);
        });
      }
    },
    async handleBinaryFile() {
      const base64Data = this.fileContents.substring(this.fileContents.indexOf("base64,") + 7);
      const currentCenterPixel = this.map.project(this.map.getCenter());
      // width: 320px on addEditPanel due to that adding 140 offset
      currentCenterPixel.x -= 160;
      const center = this.map.unproject(currentCenterPixel);
      const request = {
        fileType: this.fileExtension,
        quality: "low",
        centerPoint: [center.lng, center.lat],
        floorPlanFileBase64: base64Data
      };
      const response = await LevelService.convertBase64ToGeojson(request, true);
      if (response.createdTimestampUtcEpochSeconds) {
        this.features = response.result.features.map((feature) => {
          if (!feature.properties.typeCode) {
            return { ...feature, properties: { ...feature.properties, typeCode: "undefined" } };
          }
          if (!feature.properties.fid) {
            feature.properties.fid = Helpers.generateUuid();
          }
          return feature;
        });
        this.$store.dispatch("CONTENT/SET_MAP_OBJECTS", { mapObjects: { features: this.features }, forceUpdate: true });
      } else {
        this.clearFile();
      }
    },
    calculateBorders(features) {
      MapHelpers.addFloorOutlineBorders(features);
    },
    onDropFile(file) {
      this.$refs.dropFileComponent.createFile(file);
    },
    onLevelTypeSelected(type) {
      this.selectedLevelType = type;
      this.setFormDirty();
    },
    async geotransform(newAnchorPoints) {
      const sourceAGeoCoordinates = this.anchorFeatures[0].geometry.coordinates;
      const sourceBGeoCoordinates = this.anchorFeatures[1].geometry.coordinates;
      const targetAGeoCoordinates = newAnchorPoints[0];
      const targetBGeoCoordinates = newAnchorPoints[1];
      const geoJson = { type: "FeatureCollection", features: this.features };

      const request = {
        sourceAGeoCoordinates,
        sourceBGeoCoordinates,
        targetAGeoCoordinates,
        targetBGeoCoordinates,
        geoJson
      };
      const response = await LevelService.geoTransform(request);
      if (response?.createdTimestampUtcEpochSeconds) {
        this.features = response?.result?.features;
      } else {
        this.$refs.anchorPointsComponent.resetAnchorPoints();
      }
      this.setFormDirty();
      this.isFileUploaded = true;
    },
    async changeAnchors(newAnchorPoints) {
      const oldAnchorAFeature = this.anchorFeatures[0];
      const oldAnchorBFeature = this.anchorFeatures[1];
      const newAnchorACoordinates = newAnchorPoints[0];
      const newAnchorBCoordinates = newAnchorPoints[1];
      const request = {
        oldAnchorAFeature,
        oldAnchorBFeature,
        newAnchorACoordinates,
        newAnchorBCoordinates
      };
      const response = await LevelService.changeAnchors(request);

      if (response?.createdTimestampUtcEpochSeconds) {
        const anchorA = response.result.features[0];
        anchorA.properties.fid = this.anchorFeatures[0].properties.fid;
        const anchorB = response.result.features[1];
        anchorB.properties.fid = this.anchorFeatures[1].properties.fid;

        const aIndex = this.features.findIndex((feature) => feature.properties.fid === anchorA.properties.fid);
        const bIndex = this.features.findIndex((feature) => feature.properties.fid === anchorB.properties.fid);
        this.features[aIndex] = anchorA;
        this.features[bIndex] = anchorB;
        this.anchorFeatures = [anchorA, anchorB];
        this.setFormDirty();
        this.isFileUploaded = true;
      }
    },

    onPositionOnEarthClicked() {
      MapHelpers.removeFloorOutlineBorders();
      if (this.isMarkCornersPopupShown) {
        return;
      }
      this.shouldDisplayMarkCornersPopup = true;
    },
    closeMarkCornersPopup() {
      this.isMarkCornersPopupShown = true;
      this.shouldDisplayMarkCornersPopup = false;
    },
    downloadOriginalFloorPlan() {
      window.open(this.floorPlanUrl, "_blank");
    }
  }
};
</script>

<style lang="scss" scoped>
.row {
  &.topic {
    .col {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.download-button:hover .custom-icon {
  filter: brightness(0) saturate(100%) invert(79%) sepia(10%) saturate(956%) hue-rotate(186deg) brightness(99%)
    contrast(98%);
}

.download-button {
  ::v-deep .v-btn__content {
    .col {
      text-align: start;
      font-size: 13px;
      line-height: 16px;
    }
  }
}

.floor-plan-title {
  font-size: 16px;
  line-height: 24px;
}

::v-deep .hidden {
  display: none !important;
}
</style>
