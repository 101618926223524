import vm from "../main";
import axios from "./PointrCloudAxios";
import Helpers from "@/helpers/Helpers";
import ToastHelpers from "@/helpers/ToastHelpers";

export default class ClientService {
  static async getClients() {
    let response;
    let clients;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.get("/clients");
    } catch (error) {
      console.error(error);
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    clients = response?.data?.results?.sort(Helpers.sortContent);
    return clients;
  }

  static async getClient(clientInternalIdentifier) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.get(`/clients/${clientInternalIdentifier}`);
    } catch (error) {
      console.error(error);
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    return response?.data?.result;
  }

  static async createClient(client) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.post("/clients", client);
    } catch (error) {
      console.error(error);
      ToastHelpers.createServiceErrorToast(
        error?.response?.data?.error,
        error?.response?.data?.error?.message || `${client.clientTitle} ${vm.$t("contents.failed")}`
      );
      return error?.response?.data?.error;
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    ToastHelpers.createSuccessToast(`${client.clientTitle} ${vm.$t("contents.created")}`);
    return response?.data;
  }

  static async updateClient(client, clientInternalIdentifier) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.patch(`/clients/${clientInternalIdentifier}`, client);
    } catch (error) {
      console.error(error);
      ToastHelpers.createServiceErrorToast(
        error?.response?.data?.error,
        error?.response?.data?.error?.message || `${client.clientTitle} ${vm.$t("contents.failed")}`
      );
      return error?.response?.data?.error;
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    ToastHelpers.createSuccessToast(`${client.clientTitle} ${vm.$t("contents.updated")}`);
    return response?.data;
  }

  static async deleteClient(clientInternalIdentifier) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.delete(`/clients/${clientInternalIdentifier}`);
    } catch (error) {
      console.error(error);
      ToastHelpers.createServiceErrorToast(
        error?.response?.data?.error,
        error?.response?.data?.error?.message || vm.$t(`delete-failed`)
      );
      return error?.response?.data?.error;
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    ToastHelpers.createSuccessToast(`Client ${vm.$t("delete-success")}`);
    return response?.data;
  }
}
