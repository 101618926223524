<template>
  <v-expansion-panels v-model="defaultOpenPanelIndex" class="content-add-edit-expansion-panels" flat>
    <v-expansion-panel active-class="expanded" class="mt-0">
      <v-expansion-panel-header>
        <PtrIcon class="expansion-panel-header-icon" icon="information" />
        <div class="expansion-panel-header-text">{{ $t(`${translationPath}basic-information`) }}</div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form v-model="isAutoCompleteValid" class="mt-2 mb-3">
          <v-row class="mb-1">
            <v-col class="py-0">
              <v-autocomplete
                id="content-type-input"
                v-model="selectedType"
                :items="typeList"
                item-text="title"
                item-value="code"
                outlined
                dense
                :label="$t(`${translationPath}types`)"
                hide-details="auto"
                :menu-props="{ closeOnContentClick: true }"
                :rules="[autoCompleteRequired]"
                @change="setFormDirty"
              >
                <template #item="{ item }">
                  <v-list-item @click="onTypeSelected(item.code)">
                    <v-list-item-content>
                      <v-list-item-title> {{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template #append>
                  <PtrIcon icon="caret-down" />
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
        <v-form ref="mdpoi" v-model="valid">
          <v-row v-if="shouldShowFeatureName" class="mt-n2 mb-1">
            <v-col class="py-0">
              <v-text-field
                id="name-input"
                v-model.trim="name"
                :rules="[rules.featureName]"
                :label="$t(featureTranslationPath + namePath)"
                hide-details="auto"
                outlined
                dense
                @keydown="setFormDirty"
              />
            </v-col>
          </v-row>
          <v-row v-if="shouldShowFeatureDescription" class="mt-0 mb-1">
            <v-col class="py-0">
              <v-textarea
                id="description-input"
                v-model="description"
                :label="$t(featureTranslationPath + 'description')"
                :rules="[rules.featureDescription]"
                hide-details="auto"
                outlined
                dense
                rows="1"
                auto-grow
                @keydown="setFormDirty"
              />
            </v-col>
          </v-row>
          <v-row v-if="shouldShowFeatureKeyword" class="mt-0 mb-1">
            <v-col class="py-0">
              <v-combobox
                id="keywords-input"
                ref="refKeywords"
                v-model="keywords"
                class="keywords-input-box"
                :label="$t(featureTranslationPath + 'keywords')"
                :rules="[rules.featureKeywords]"
                outlined
                hide-details
                dense
                small-chips
                multiple
                append-icon=""
                @keydown="setFormDirty"
              >
                <template #selection="data">
                  <v-chip
                    :key="JSON.stringify(data.item)"
                    color="primary"
                    outlined
                    class="keyword-chip mt-1"
                    small
                    v-bind="data.attrs"
                    close
                    :input-value="data.selected"
                    :disabled="data.disabled"
                    @click:close="data.parent.selectItem(data.item)"
                  >
                    {{ data.item }}
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
          </v-row>
          <v-row class="mt-0 mb-1">
            <v-col class="py-0">
              <v-text-field
                id="eid-input"
                v-model="eid"
                :label="$t(featureTranslationPath + 'eid')"
                hide-details="auto"
                outlined
                dense
                @keydown="setFormDirty"
              />
            </v-col>
          </v-row>
          <GeometrySection
            v-if="selectedType"
            ref="geometrySection"
            :feature-id="featureId"
            :should-show-polygon-icon="shouldShowPolygonIcon"
            :should-show-point-icon="shouldShowPointIcon"
            :is-edit="isEdit"
          ></GeometrySection>
        </v-form>
        <div class="form-footer mt-5">
          {{ $t(`${translationPath}required`) }}
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel active-class="expanded" class="mt-0">
      <v-expansion-panel-header>
        <PtrIcon class="expansion-panel-header-icon" icon="custom-integration" />
        <div class="expansion-panel-header-text">{{ $t(`${translationPath}custom-integration`) }}</div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <CustomIntegration
          ref="customIntegration"
          :extra-data-prop="extraData"
          :feature-id="featureId"
          @integrationUpdated="setFormDirty"
          @setFormDirty="setFormDirty"
        ></CustomIntegration>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <slot name="danger-zone"></slot>
  </v-expansion-panels>
</template>

<script>
import { mapState } from "vuex";
import GeometrySection from "@/components/mapDesigner/GeometrySection.vue";
import PointMode from "@/helpers/drawModes/PointMode";
import ValidationHelpers from "@/helpers/ValidationHelpers";
import ContentService from "@/services/ContentService";
import CustomIntegration from "@/components/shared/CustomIntegration.vue";
import PtrIcon from "@/components/shared/PtrIcon.vue";
import MapHelpers from "@/helpers/MapHelpers";
import Helpers from "@/helpers/Helpers";

const DISPLAY_MODE_UNAVAILABLE = "unavailable";
const DISPLAY_MODE_REQUIRED = "required";

export default {
  components: { GeometrySection, CustomIntegration, PtrIcon },
  props: {
    featureId: [String, Number]
  },
  data: () => ({
    translationPath: "contents.mapDesigner.",
    featureTranslationPath: "contents.feature.",
    feature: {},
    selectedType: undefined,
    featureNameDisplayMode: "",
    featureDescriptionDisplayMode: "",
    featureKeywordDisplayMode: "",
    taxonomyType: "",
    name: undefined,
    description: "",
    eid: "",
    keywords: [],
    valid: false,
    isAutoCompleteValid: false,
    isEdit: false,
    defaultOpenPanelIndex: 0,
    extraData: {}
  }),
  computed: {
    ...mapState("CONTENT", [
      "pois",
      "mapObjects",
      "poiTypeCodesObj",
      "mapObjectsTypeCodesObj",
      "isFormDirty",
      "buildings"
    ]),
    ...mapState("MAP", [
      "mapDraw",
      "currentSite",
      "currentBuilding",
      "currentLevel",
      "drawnCoordinates",
      "mapMode",
      "isMapBorderEnabled"
    ]),
    taxonomy() {
      return this.$store.state.taxonomy;
    },
    typeList() {
      let suggestedTypeCodesArr = [];
      let suggestedTypes = [];
      let allTypes = [];

      suggestedTypeCodesArr = Object.keys(this.mapObjectsTypeCodesObj);
      suggestedTypes = Object.values(this.taxonomy).filter((item) => suggestedTypeCodesArr.includes(item.code));
      allTypes = Object.values(this.taxonomy).filter(
        (item) => item.properties.class === "none" || item.properties.class === "poi"
      );
      let autoCompleteItems = [];
      if (suggestedTypes.length) {
        autoCompleteItems = [
          {
            header: "SUGGESTED TYPES"
          },
          ...suggestedTypes
        ];
      }
      return [
        ...autoCompleteItems,
        {
          header: "ALL TYPES"
        },
        ...allTypes
      ];
    },
    shouldShowFeatureName() {
      return this.featureNameDisplayMode !== DISPLAY_MODE_UNAVAILABLE;
    },
    shouldShowFeatureDescription() {
      return this.featureDescriptionDisplayMode !== DISPLAY_MODE_UNAVAILABLE;
    },
    shouldShowFeatureKeyword() {
      return this.featureKeywordDisplayMode !== DISPLAY_MODE_UNAVAILABLE;
    },
    rules() {
      return {
        featureName: (value) => {
          return this.featureNameDisplayMode === DISPLAY_MODE_REQUIRED
            ? ValidationHelpers.isRequired(value?.trim())
            : true;
        },
        featureDescription: (value) => {
          return this.featureDescriptionDisplayMode === DISPLAY_MODE_REQUIRED
            ? ValidationHelpers.isRequired(value)
            : true;
        },
        featureKeywords: (value) => {
          return this.featureKeywordDisplayMode === DISPLAY_MODE_REQUIRED ? ValidationHelpers.isRequired(value) : true;
        },
        required: (value) => ValidationHelpers.isRequired(value)
      };
    },
    shouldShowPointIcon() {
      return this.taxonomyType?.properties?.shape?.includes("point") ? true : false;
    },
    shouldShowPolygonIcon() {
      return this.taxonomyType?.properties?.shape?.includes("polygon") ? true : false;
    },
    namePath() {
      if (this.featureNameDisplayMode === DISPLAY_MODE_REQUIRED) {
        return "name-required";
      }
      return "name";
    }
  },
  watch: {
    featureId: {
      immediate: true,
      handler() {
        this.$store.dispatch("MAP/DRAWN_POLYGON_CHANGED", { feature: undefined, shouldKeepFormClean: true });
        this.$store.commit("MAP/DRAWN_COORDINATES", undefined);
        PointMode.clearPoints();
        this.feature = this.mapObjects.find((mapObject) => mapObject.properties.fid === this.featureId);
        this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
        if (this.feature) {
          this.isEdit = true;
          this.selectedType = this.feature.properties.typeCode;
          this.name = this.feature.properties.name;
          this.description = this.feature.properties.description;
          this.eid = this.feature.properties.eid;
          this.keywords =
            typeof this.feature.properties.keywords === "string"
              ? [this.feature.properties.keywords]
              : this.feature.properties.keywords;
          this.extraData = this.feature.properties?.extra || this.feature.properties?.extraData;
          this.taxonomyType = this.taxonomy[this.selectedType];
          this.$refs?.geometrySection?.reset();
          this.$store.commit("MAP/DRAWN_COORDINATES", this.feature.geometry.coordinates);

          if (this.feature.geometry.type === "Polygon") {
            this.$store.dispatch("MAP/DRAWN_POLYGON_CHANGED", { feature: this.feature, shouldKeepFormClean: true });
          } else {
            PointMode.addPoint(this.drawnCoordinates || [], {
              draggable: false,
              fid: this.feature.properties.fid
            });
          }
        }
      }
    },
    selectedType() {
      this.taxonomyType = this.taxonomy[this.selectedType];
      this.parseTypeForInputs();
      this.featureNameDisplayMode = this.taxonomyType?.properties?.isTitleEnabled;
      this.featureDescriptionDisplayMode = this.taxonomyType?.properties?.isDescriptionEnabled;
      this.featureKeywordDisplayMode = this.taxonomyType?.properties?.keyword;
    },
    valid() {
      this.emitValidationStatusForForm();
    },
    isAutoCompleteValid() {
      this.emitValidationStatusForForm();
    },
    drawnCoordinates() {
      this.emitValidationStatusForForm();
    },
    isMapBorderEnabled() {
      this.emitValidationStatusForForm();
    }
  },
  created() {
    MapHelpers.enableMapInteractions();
    this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
    this.parseTypeForInputs();
  },
  beforeDestroy() {
    MapHelpers.disableMapInteractions();
    this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
  },
  methods: {
    //TODO: Move to a helper
    autoCompleteRequired(value) {
      if (value === null || value === undefined) {
        this.isAutoCompleteValid = false;
        // TODO: Move to language file
        return this.$t("contents.validations.type-must-be-selected");
      }
      this.isAutoCompleteValid = true;
      return true;
    },
    async save() {
      let coordinates;
      try {
        coordinates = JSON.parse(this.drawnCoordinates || "[]");
      } catch (e) {
        coordinates = this.drawnCoordinates;
      }

      const isCoordinatesPolygon = Array.isArray(coordinates?.[0]);
      const isMultiPolygon = this.mapDraw.getAll()?.features?.[0]?.geometry?.type === "MultiPolygon";
      const geometryType = isCoordinatesPolygon ? "Polygon" : "Point";

      if (isMultiPolygon) {
        let isSuccess;
        await Promise.all(
          coordinates.map(async (coordinate, index) => {
            if (index === 0) {
              isSuccess = await this.createRequestAndPut(
                this.feature?.properties?.fid,
                this.name,
                {
                  type: geometryType,
                  coordinates: coordinate
                },
                true
              );
            } else {
              let extraPolygonName = Helpers.generateUuid();
              isSuccess = await this.createRequestAndPut(
                undefined,
                extraPolygonName,
                {
                  type: geometryType,
                  coordinates: coordinate
                },
                false
              );
            }
          })
        );
        return isSuccess;
      } else {
        return await this.createRequestAndPut(
          this.feature?.properties?.fid,
          this.name,
          {
            type: geometryType,
            coordinates: coordinates
          },
          true
        );
      }
    },
    async createRequestAndPut(fid, name, geometry, isEdit) {
      if (this.$refs.refKeywords) {
        this.$refs.refKeywords.blur();
      }
      return await this.$nextTick().then(async () => {
        let extraData = this.$refs?.customIntegration?.getExtraData();
        if (!extraData && this.feature) {
          extraData = this.feature.properties?.extra || this.feature.properties?.extraData;
        }
        const request = {
          type: "Feature",
          properties: {
            typeCode: this.selectedType,
            fid: fid,
            eid: this.eid?.trim(),
            sid: Number(this.currentSite),
            bid: Number(this.currentBuilding),
            lvl: Number(this.currentLevel),
            name: name?.trim(),
            description: this.description?.trim(),
            keywords: this.keywords || [],
            extra: extraData,
            style: {}
          },
          geometry: geometry
        };
        const response = await ContentService.putFeature(request, ContentService.CONTENT_TYPES.MAP_OBJECT, this.isEdit);

        if (response?.createdTimestampUtcEpochSeconds) {
          this.$store.dispatch("CONTENT/UPDATE_FEATURE", { feature: request, isEdit: this.isEdit && isEdit });
          this.$store.dispatch("CONTENT/SET_LOCAL_CHANGES");
          this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
          return true;
        } else {
          return false;
        }
      });
    },

    async deleteContent() {
      const response = await ContentService.deleteFeature(this.feature);
      if (response?.createdTimestampUtcEpochSeconds) {
        this.$store.dispatch("CONTENT/UPDATE_FEATURE", { feature: this.feature, isDelete: true });
        this.$store.dispatch("CONTENT/SET_LOCAL_CHANGES");
        this.$store.commit("CONTENT/IS_FORM_DIRTY", false);
        return true;
      } else {
        return false;
      }
    },
    parseTypeForInputs() {
      this.featureNameDisplayMode = this.taxonomyType?.properties?.isTitleEnabled;
      this.featureDescriptionDisplayMode = this.taxonomyType?.properties?.isDescriptionEnabled;
      this.featureKeywordDisplayMode = this.taxonomyType?.properties?.keyword;
    },
    emitValidationStatusForForm() {
      this.$emit(
        "valid",
        this.valid &&
          this.isAutoCompleteValid &&
          this.drawnCoordinates &&
          this.drawnCoordinates?.length !== 0 &&
          !this.isMapBorderEnabled
      );
    },
    onTypeSelected(type) {
      this.selectedType = type;
      this.setFormDirty();
    },
    setFormDirty() {
      this.$store.commit("CONTENT/IS_FORM_DIRTY", true);
    }
  }
};
</script>
