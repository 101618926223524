<template>
  <div>
    <v-row class="mt-0 mb-2 d-flex justify-space-between align-center">
      <div class="content-add-edit-subtitle">{{ $t(`${translationPath}additionalProperties`) }}</div>
      <AddNewPropertyButton
        :added-properties="properties"
        @propertyAdded="(item) => addNewProperty(item)"
      ></AddNewPropertyButton>
    </v-row>
    <v-form v-if="filteredProperties.length" ref="additionalPropertyForm" v-model="isInputsValid">
      <AdditionalPropertyInput
        v-for="propertyName in filteredProperties"
        :ref="`${propertyName}-input`"
        :key="propertyName"
        :name="propertyName"
        :initial-value="properties[propertyName]"
        @propertyDeleted="(item) => deleteProperty(item)"
        @propertyUpdated="(item, newValue) => updateProperty(item, newValue)"
        @isCustomInputValid="(value, name) => handleIsCustomInputValid(value, name)"
      ></AdditionalPropertyInput>
    </v-form>

    <EmptyList v-else icon-name="cloud-up" icon-color="#96b3f8" icon-width="28" vertical>
      <template #header>
        {{ $t(`${translationPath}noAdditionalProperties`) }}
      </template>
      <template #description>
        {{ $t(`${translationPath}emptyContentDescription`) }}
      </template>
    </EmptyList>
  </div>
</template>
<script>
import AddNewPropertyButton from "@/components/mapDesigner/AddNewPropertyButton.vue";
import AdditionalPropertyInput from "./AdditionalPropertyInput.vue";
import EmptyList from "@/components/shared/EmptyList.vue";
import poiProperties from "@/constants/poiProperties";
export default {
  components: { AdditionalPropertyInput, AddNewPropertyButton, EmptyList },
  props: {
    properties: Object
  },
  data: () => ({
    translationPath: "contents.mapDesigner.",
    isInputsValid: false,
    // keep track of validity state of custom inputs separately
    customInputsValidity: {
      phoneNumber: true,
      logo_upload: true,
      images_upload: true
    }
  }),
  computed: {
    constantProperties() {
      return poiProperties.POI_PROPERTIES;
    },
    filteredProperties() {
      /**
       * priceSign & priceMax are not selected by user for now.
       * Their default values ($, 4) are given in the request but they are not given user as option
       */

      return Object.keys(this.properties).filter(
        (propertyName) =>
          propertyName !== "priceSign" &&
          propertyName !== "priceMax" &&
          this.constantProperties.find((prop) => prop.key === propertyName)
      );
    },
    isAllCustomInputsValid() {
      const validityState = Object.values(this.customInputsValidity);
      return validityState.every((value) => value === true);
    },
    isAllInputsValid() {
      return this.isInputsValid && this.isAllCustomInputsValid;
    }
  },
  watch: {
    isAllInputsValid() {
      this.$emit("valid", this.isAllInputsValid);
    }
  },
  created() {
    if (Object.keys(this.properties).length === 0) {
      this.isInputsValid = true;
    }
  },
  methods: {
    /**
     * Called when an additional property is added from menu
     */
    addNewProperty(item) {
      this.$emit("propertyAdded", item);
    },
    /**
     * Called when an additional property is deleted by clicking thrash icon
     */
    deleteProperty(item) {
      // reset its validity state if the deleted prop is a custom one
      this.resetValidity(item);
      this.$emit("propertyDeleted", item);
    },
    /**
     * Called when an additional property's value is updated
     */
    updateProperty(item, newValue) {
      this.$emit("propertyUpdated", item, newValue);
    },
    updateInputs() {
      Object.keys(this.properties).forEach((propertyName) => {
        this.$refs[`${propertyName}-input`]?.[0]?.updateInputs();
      });
    },
    //resets validation
    resetFormValidation() {
      this.$refs.additionalPropertyForm.resetValidation();
    },
    handleIsCustomInputValid(value, name) {
      this.customInputsValidity[name] = value;
    },
    resetValidity(name) {
      this.customInputsValidity[name] = true;
    }
  }
};
</script>
