<template>
  <div>
    <v-row class="mt-0 mb-2">
      <v-col cols="12" sm="6" class="py-0 d-flex align-center justify-center">
        <v-btn class="v-btn--square" small icon depressed plain @click.stop="updateHour(1)">
          <PtrIcon icon="caret-up" width="10" />
        </v-btn>
      </v-col>
      <v-col cols="12" sm="6" class="py-0 d-flex align-center justify-center">
        <v-btn class="v-btn--square" small icon depressed plain @click.stop="updateMinute(5)">
          <PtrIcon icon="caret-up" width="10" />
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-0 mb-2">
      <v-col cols="12" sm="6" class="py-0">
        <div class="hour-min-value">{{ `${hour}`.padStart(2, "0") }}</div>
      </v-col>
      <v-col cols="12" sm="6" class="py-0">
        <div class="hour-min-value">{{ `${minute}`.padStart(2, "0") }}</div>
      </v-col>
    </v-row>
    <v-row class="mt-0 mb-2">
      <v-col cols="12" sm="6" class="py-0 d-flex align-center justify-center">
        <v-btn class="v-btn--square" small icon depressed plain @click.stop="updateHour(-1)">
          <PtrIcon icon="caret-down" width="10" />
        </v-btn>
      </v-col>
      <v-col cols="12" sm="6" class="py-0 d-flex align-center justify-center">
        <v-btn class="v-btn--square" small icon depressed plain @click.stop="updateMinute(-5)">
          <PtrIcon icon="caret-down" width="10" />
        </v-btn>
      </v-col>
    </v-row>
    <v-switch
      :input-value="isClosed"
      class="mt-0"
      :label="$t(`${translationPath}closed`)"
      :color="isClosed ? 'neutral' : 'primary'"
      hide-details
      inset
      @change="(newVal) => $emit('isClosedSwitched', newVal)"
    ></v-switch>
    <v-switch
      :input-value="isAlwaysOpen"
      class="mt-0"
      :label="$t(`${translationPath}always-open`)"
      :color="isAlwaysOpen ? 'neutral' : 'primary'"
      hide-details
      inset
      @change="(newVal) => $emit('isAlwaysOpenSwitched', newVal)"
    ></v-switch>
  </div>
</template>
<script>
import PtrIcon from "@/components/shared/PtrIcon.vue";
export default {
  components: { PtrIcon },
  props: {
    hour: Number,
    minute: Number,
    isClosed: Boolean,
    isAlwaysOpen: Boolean,
    maxHour: { type: Number, default: 23 },
    minHour: { type: Number, default: 0 },
    maxMinute: Number,
    minMinute: Number
  },
  data: () => ({
    translationPath: "contents.feature."
  }),
  methods: {
    updateMinute(offset) {
      const newMinute = (this.minute + 60 + offset) % 60;
      if (newMinute > this.maxMinute || newMinute < this.minMinute) {
        return;
      }
      this.$emit("minuteUpdated", `${newMinute}`);
    },
    updateHour(offset) {
      const newHour = (this.hour + 24 + offset) % 24;
      if (newHour > this.maxHour || newHour < this.minHour) {
        return;
      }
      this.$emit("hourUpdated", `${newHour}`);
    }
  }
};
</script>
