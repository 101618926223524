import { v4 as uuidv4 } from "uuid";
import store from "../store/store";
import router from "../router/index";

export default class Helpers {
  static generateUuid() {
    return uuidv4();
  }

  static parseJSON(str) {
    try {
      if (typeof str === "string") {
        return JSON.parse(str);
      } else if (typeof str === "object") {
        return str;
      } else {
        return str;
      }
    } catch (error) {
      console.debug("Could not parse str", error);
      return str;
    }
  }

  static lazyLoad(view, path = "views") {
    return () => import(`@/${path}/${view}.vue`);
  }

  static generateStringArrayWithFilterRules(feature) {
    // TODO: There shouldn't be string keyword
    if (typeof feature?.properties?.keywords === "string") {
      feature.properties.keywords = [feature.properties.keywords];
    }
    if (feature?.properties === undefined) {
      return [];
    }
    return [
      feature.properties.name,
      feature.properties.name?.toString().split(" "),
      feature.properties.fid,
      feature.properties.eid,
      feature.properties.description,
      feature.properties.description?.split(" "),
      feature.properties.typeCode,
      feature.properties.typeCode?.split("-"),
      feature.properties.typeCode?.replace("-", " "),
      feature.properties.keywords?.map((str) => String(str)).map((str) => str.split(" ")),
      feature.properties.uuid,
      feature.properties.major,
      feature.properties.minor,
      feature.properties.major + "X" + feature.properties.minor
    ]
      .filter((n) => n) // remove invalid fields
      .flat()
      .map(this.clearText);
  }

  static clearText(str) {
    if (typeof str != "string") {
      return str;
    }
    return str.trim().toLowerCase();
  }

  static sortContent(contentObj1, contentObj2) {
    let sortedBy;
    if (contentObj1.clientTitle) {
      sortedBy = "clientTitle";
    } else if (contentObj1.siteTitle) {
      sortedBy = "siteTitle";
    } else if (contentObj1.buildingTitle) {
      sortedBy = "buildingTitle";
    } else if (contentObj1.levelIndex) {
      return contentObj1.levelIndex - contentObj2.levelIndex;
    }
    let first = contentObj1[sortedBy]?.toUpperCase();
    let second = contentObj2[sortedBy]?.toUpperCase();
    return first < second ? -1 : 1;
  }

  static escapeRegExp(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  static arrayToObject(arr) {
    try {
      return arr.reduce((accum, [k, v]) => {
        accum[k] = v;
        return accum;
      }, {});
    } catch (err) {
      console.debug(`Failed to convert array(${arr}) to Object`, err);
      return arr;
    }
  }

  static routerSafeGoBack(pathName) {
    const routerString = localStorage.getItem("publishRoute");
    let routerObject;
    try {
      routerObject = JSON.parse(routerString);
    } catch {
      // Don't do anything - just warn
      console.warn("Failed to parse router string");
    }

    if (routerObject === undefined) {
      router.push({ name: pathName }).catch((e) => console.log(e.message));
    } else {
      localStorage.removeItem("publishRoute");
      router.push({ name: routerObject.name, params: routerObject.params }).catch((e) => console.log(e.message));
    }
  }

  /**
   * If array has value, removes it, otherwise adds it
   * @param arr: array of primite types
   *    toggleArrayItem([1, 2], 1) changes array to [2]
   *    toggleArrayItem([1, 2], 3) changes array to [1, 2, 3]
   */
  static toggleArrayItem(arr, val) {
    const i = arr.indexOf(val);
    if (i === -1) {
      arr.push(val);
    } else {
      arr.splice(i, 1);
    }
  }

  /**
   * If array has object with field which has given value, removes it, otherwise adds { field: val } to array
   * example:
   *    toggleArrayObject([{a: 1}, {b: 2}], 1, "a") changes array to [{b: 2}]
   *    toggleArrayObject([{a: 1}, {b: 2}], 3, "c") changes array to [{a: 1}, {b: 2}, {c: 3}]
   * @param arr: array of objects
   * @param field: field to check on object
   */
  static toggleArrayObject(arr, val, field) {
    const i = arr.map((obj) => obj[field]).indexOf(val);
    if (i === -1) {
      arr.push({ [field]: val });
    } else {
      arr.splice(i, 1);
    }
  }
}
