<template>
  <v-menu
    v-model="isActive"
    :disabled="isDisabled"
    offset-y
    content-class="breadcrumb-wrapper ptr-tooltip-menu tooltip-top"
    min-width="240"
  >
    <template #activator="{ on, attrs }">
      <v-col
        v-show="breadcrumbText"
        class="breadcrumb text-no-wrap ml-n4 d-flex align-center"
        :class="{ 'active-breadcrumb': isActive, 'disabled-breadcrumb': isDisabled }"
        v-bind="attrs"
        v-on="on"
      >
        <div class="clickable">{{ breadcrumbText }}</div>

        <PtrIcon icon="caret-down" editable-color width="12" class="caret-down" />
      </v-col>
    </template>

    <v-card v-if="breadcrumbList.length" class="tooltip-menu-card">
      <v-list dense class="tooltip-menu-content">
        <v-list-item class="tooltip-header pl-2">
          <v-row no-gutters>
            <v-col cols="auto">
              <v-btn
                color="white"
                class="v-btn--square mr-2"
                small
                icon
                depressed
                plain
                @click="$router.push({ name: 'Clients' })"
              >
                <PtrIcon icon="home" width="28" height="28" />
              </v-btn>
            </v-col>
            <v-col v-if="breadcrumbList[0].parentTitle">
              <v-list-item-title class="tooltip-menu-title">
                <strong>{{ breadcrumbList[0].parentTitle }}</strong>
              </v-list-item-title>
            </v-col>
          </v-row>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item
          v-for="({ title, route, disable }, index) in breadcrumbList"
          :key="index"
          :to="route"
          active-class="active-breadcrumb-item"
          dense
          class="breadcrumb-item flat-list-item"
          :disabled="disable"
          @click="handleRouteChange(index)"
        >
          <v-list-item-title>{{ title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import PtrIcon from "@/components/shared/PtrIcon";
import PointMode from "@/helpers/drawModes/PointMode";
import GraphMode from "@/helpers/drawModes/GraphMode";
import LevelStatusHelper from "@/helpers/LevelStatusHelper";

export default {
  name: "PtrBreadcrumb",
  components: { PtrIcon },
  data: () => ({
    isActive: false,
    buildingObject: undefined,
    levelObject: undefined
  }),

  computed: {
    ...mapState("MAP", ["isAddEditPanelActive"]),
    ...mapState("CONTENT", ["levelStatuses", "isFormDirty"]),
    breadcrumbList() {
      return this.$store.state.breadcrumbList;
    },
    isItInContentPagesAndIsThereAnyLevelStatusIsInProgress() {
      return this.$route.name !== "Levels" && LevelStatusHelper.isAnyLevelInProgress(this.levelStatuses);
    },
    isDisabled() {
      return this.isAddEditPanelActive;
    },
    breadcrumbText() {
      let breadcrumb;
      let currentRouteName = this.$route.name;
      if (currentRouteName.includes("Client")) {
        this.$emit("textUpdated", "");
      } else if (currentRouteName.includes("Site") || currentRouteName.includes("GlobalGeofence")) {
        breadcrumb = this.currentClientObject?.clientTitle;
        this.updateBreadCrumbList(this.clients, {
          routeName: "Sites",
          routeParamKey: "clientId",
          routeNameKey: "clientTitle",
          routeIdKey: "clientInternalIdentifier"
        });
        this.$emit("textUpdated", "");
      } else if (currentRouteName.includes("Building")) {
        breadcrumb = this.currentSiteObject?.siteTitle;
        this.updateBreadCrumbList(this.sites, {
          routeName: "Buildings",
          routeParamKey: "siteId",
          routeNameKey: "siteTitle",
          routeIdKey: "siteInternalIdentifier",
          parentTitle: this.currentClientObject?.clientTitle || ""
        });
        this.$emit(
          "textUpdated",
          this.$t("breadcrumb.tooltip", {
            item: this.$t("breadcrumb.site"),
            name: this.currentSiteObject?.siteTitle ? `'${this.currentSiteObject?.siteTitle}'` : ""
          })
        );
      } else if (currentRouteName.includes("Level")) {
        breadcrumb = this.buildingObject?.buildingTitle;
        this.updateBreadCrumbList(this.buildings, {
          routeName: "Levels",
          routeParamKey: "buildingId",
          routeNameKey: "buildingTitle",
          routeIdKey: "buildingInternalIdentifier",
          parentTitle: this.currentSiteObject?.siteTitle || ""
        });
        this.$emit(
          "textUpdated",
          this.$t("breadcrumb.tooltip", {
            item: this.$t("breadcrumb.building"),
            name: this.buildingObject?.buildingTitle ? `'${this.buildingObject.buildingTitle}'` : ""
          })
        );
      } else if (currentRouteName.includes("Picker")) {
        breadcrumb = this.getSubRouteBreadcrumb;
        this.updateBreadCrumbList(this.levels, {
          routeName: "ContentPicker",
          routeParamKey: "levelId",
          routeNameKey: "levelLongTitle",
          routeIdKey: "levelIndex",
          parentTitle: this.buildingObject?.buildingTitle || ""
        });
        this.$emit("textUpdated", this.$t("breadcrumb.tooltip", { item: this.$t("breadcrumb.level") }));
      } else if (
        currentRouteName.includes("PoiList") ||
        currentRouteName.includes("GeofenceList") ||
        currentRouteName.includes("MapObjectList") ||
        currentRouteName.includes("BeaconList") ||
        currentRouteName.includes("WayfindingNetwork")
      ) {
        breadcrumb = this.getSubRouteBreadcrumb;
        this.updateBreadCrumbList(this.levels, {
          routeName: currentRouteName,
          routeParamKey: "levelId",
          routeNameKey: "levelLongTitle",
          routeIdKey: "levelIndex",
          disableItems: true,
          parentTitle: this.buildingObject?.buildingTitle || ""
        });
        this.$emit("textUpdated", this.$t("breadcrumb.tooltip", { item: this.$t("breadcrumb.level") }));
      } else {
        breadcrumb = this.getSubRouteBreadcrumb;
        this.updateBreadCrumbList(this.levels, {
          routeName: "MapDesigner",
          routeParamKey: "levelId",
          routeNameKey: "levelLongTitle",
          routeIdKey: "levelIndex",
          parentTitle: this.buildingObject?.buildingTitle || ""
        });
        this.$emit("textUpdated", this.$t("breadcrumb.tooltip", { item: this.$t("breadcrumb.level") }));
      }
      return breadcrumb;
    },
    getSubRouteBreadcrumb() {
      return this.levelObject?.levelLongTitle || "";
    },
    ...mapState("CONTENT", ["clients", "sites", "buildings", "levels"]),
    ...mapGetters("MAP", ["currentClientObject", "currentSiteObject"])
  },

  watch: {
    buildings() {
      this.handleBuildingLevelObjectChange();
    },
    $route: {
      handler() {
        this.handleBuildingLevelObjectChange();
      },
      immediate: true
    }
  },

  methods: {
    isLevelStatusInProgress(levelIndex) {
      return LevelStatusHelper.isInProgressState(this.levelStatuses[levelIndex]?.levelStatus);
    },
    updateBreadCrumbList(listData, options) {
      let list = listData.map((element) => {
        return {
          title: element[options.routeNameKey],
          parentTitle: options.parentTitle,
          route: { name: options.routeName, params: { [options.routeParamKey]: element[options.routeIdKey] } },
          disable: options?.disableItems ? this.isLevelStatusInProgress(element?.levelIndex) : false
        };
      });
      this.$store.commit("BREADCRUMB_LIST", list);
    },
    handleRouteChange(index) {
      if (typeof this.breadcrumbList?.[index]?.route?.params?.levelId !== "undefined") {
        this.$store.commit("CONTENT/RESET_CONTENT_VISIBILITY");
        this.$store.dispatch("MAP/ADD_MAP_OBJECTS_TO_MAP");
        this.$store.commit("CONTENT/ORIGINAL_MAP_OBJECTS", []);
        PointMode.clearPoints();
        GraphMode.clear();
      }
      if (!this.isFormDirty) {
        this.$store.commit("CONTENT/INCR_REFRESH_KEY");
      }
    },
    handleBuildingLevelObjectChange() {
      const buildingId = this.$route.params.buildingId;
      const levelId = this.$route.params.levelId;
      if (buildingId !== undefined) {
        this.buildingObject = this.buildings?.find((building) => building.buildingInternalIdentifier == buildingId);
      }
      if (levelId !== undefined && this.buildingObject) {
        this.levelObject = this.buildingObject?.levels?.find((level) => level.levelIndex == levelId);
      }
    }
  }
};
</script>
