<template>
  <v-tooltip right :open-on-hover="false" :content-class="`help-content pa-4 ${className}`">
    <template #activator="{ on }">
      <v-btn small depressed plain icon retain-focus-on-click v-on="on" @click="on.click" @blur="on.blur">
        <PtrIcon icon="question-mark" :height="iconHeight || 18" />
      </v-btn>
    </template>
    <span v-if="content" class="d-flex flex-column">
      <img v-if="content.imageLink" :src="content.imageLink" alt="Content Image" />
      <div v-if="content.header" class="hoverable-header mb-2">{{ content.header }}</div>
      <div v-if="content.description" class="hoverable-description">{{ content.description }}</div>
    </span>
    <slot name="html-header"></slot>
    <slot name="html-content"></slot>
  </v-tooltip>
</template>

<script>
import PtrIcon from "@/components/shared/PtrIcon.vue";
export default {
  components: { PtrIcon },
  props: {
    content: Object,
    className: {
      type: String,
      default: ""
    },
    iconHeight: String
  }
};
</script>
<style lang="scss">
.help-content {
  width: 296px;
  background-color: #2e3138;
  color: white;
  box-shadow: 0px 4px 8px rgba(23, 25, 28, 0.16);
  border-radius: 8px;
  pointer-events: all;
  opacity: 1;
  overflow: inherit !important; // that's important to make the pseudo-element visible outside the main container
  z-index: 20;

  &::before {
    content: "";
    position: absolute;
    top: calc(50% - 10px);
    bottom: 0;
    left: -8px;
    z-index: 21;
    width: 0px;
    height: 0px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #2e3138;
    box-sizing: border-box;
  }
  .hoverable-header {
    font-weight: 600;
    line-height: 24px;
    font-size: 16px;
  }
  .hoverable-description {
    font-weight: 400;
    line-height: 18px;
    font-size: 13px;
  }
  a {
    color: white;
  }
}
</style>
