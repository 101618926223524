import vm from "../main";
import CustomToast from "@/components/shared/CustomToast.vue";

export default class ToastHelpers {
  static createSuccessToast(message) {
    vm.$toast.success({
      component: CustomToast,
      props: { message: message }
    });
  }

  static createWarningToast(message) {
    vm.$toast.warning({
      component: CustomToast,
      props: { message: message }
    });
  }

  static createErrorToast(message) {
    vm.$toast.error({
      component: CustomToast,
      props: { message: message }
    });
  }

  static createServiceErrorToast(response, message) {
    if (
      response?.message ===
      "Cannot change content while staging or publish is in progress. If you would like to edit content still, please wait for the ongoing progress to finish or cancel it."
    ) {
      this.createWarningToast(message);
    } else {
      this.createErrorToast(message);
    }
  }
}
